// extracted by mini-css-extract-plugin
export var aArrow1 = "vd";
export var aArrow2 = "wd";
export var aButton1 = "fd";
export var aButton2 = "gd";
export var aButton3 = "hd";
export var aButton4 = "id";
export var aButton5 = "jd";
export var aButtonHeaderLogin = "kd";
export var aButtonHeaderRegister = "ld";
export var aButtonHeaderSpLogin = "od";
export var aButtonLogin = "nd";
export var aButtonRegister = "md";
export var aEmpty = "cd";
export var aTagAnnounce = "sd";
export var aTagClose = "rd";
export var aTagEntry = "pd";
export var aTagFree = "ud";
export var aTagLottery = "td";
export var aTagPayment = "qd";
export var aText = "xd";
export var aTitle = "dd";
export var animationMarquee = "yd";
export var pProductsList = "ed";